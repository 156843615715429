.add_edit_user_tag {
  color: #014b7e;
  text-align: center;
}
.add_edit_user_edit_icon {
  color: #014b7e;
  float: right;
  cursor: pointer;
  margin-top: -5%;
}
.add_edit_user {
  margin: 3% auto 0%;
  width: 78%;
  padding: 0% 1%;
}
.add_edit_user_role {
  width: 60%;
  margin-left: 5%;
  margin-bottom: 3.5%;
}
.add_edit_user_error {
  color: red;
  margin-left: 5%;
  display: block;
}
.add_edit_user_textFields {
  display: block;
  padding: 0% 1%;
  border: none;
  border-bottom: 1px solid #014b7e;
  color: #014b7e !important;
  padding-left: 0;
  width: 85%;
  outline: none;
  color: var(--secondary-blue);
  margin: 0% 5%;
}
.create_user_role_wrapper {
  margin: 5px 0px;
}
.textFieldsContainer {
  margin: 0px 0px 20px;
}

.backButtonUsers {
  margin-top: 25px !important;
  margin-right: 25px !important;
  padding: 7px !important;
}
.create_users_breadcrumb_container {
  margin: 20px auto;
  width: 95%;
}

.custom_button_create_user{
  width: 15% !important;
  text-transform: uppercase;
  cursor: pointer;
  margin-top: 30px !important;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  outline: none !important;
}

@media screen and (max-width: 700px) {
  .backButtonUsers {
    padding: 3px !important;
    margin-bottom: 20px !important;
  }
  .add_edit_user {
    width: 100%;
  }
  .add_edit_user_edit_icon {
    margin-top: 5px !important;
  }
  .profile_wrapper {
    flex-direction: column;
  }
  .profile_details_wrapper {
    width: 100% !important;
  }
  .user_image_wrapper {
    width: 100%;
    margin-bottom: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .profile_update_button_wrapper {
    width: 100% !important;
  }
  .custom_button_create_user{
    width: 90% !important;
  }
  .add_edit_user_role {
    margin-bottom: 6%;
    width: 85% !important;
  }
}

.questionTypeCounts:first-child {
  /* border-radius: 20px 0 0 20px; */
  border-top-left-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
}
.questionTypeCounts:last-child {
  border-top-right-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

@media (max-width: 1600px) {
  .QuestionTypeBar {
    font-size: 10px;
    padding: 1%;
  }
  .DifficultyLabel {
    margin-bottom: -2%;
  }
  .Doughnut {
    width: 40%;
    height: 40%;
  }
}
@media (max-width: 1045px) {
  .QuestionTypeBar {
    font-size: 9px;
    padding: 1%;
  }
  .Doughnut {
    width: 20%;
    height: 20%;
  }
}

@media (max-width: 955px) {
  .DifficultyLabel {
    margin-bottom: 1%;
  }
  .Doughnut {
    width: 20%;
    height: 20%;
  }
}

@media (max-width: 400px) {
  .QuestionTypeBar {
    font-size: 8px;
    /* padding: 1%; */
  }
  .DifficultyLabel {
    margin-bottom: 0;
  }
}

@media (max-width: 300px) {
  .QuestionTypeBar {
    font-size: 7px;
    /* padding: 1%; */
  }
}

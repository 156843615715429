.tox-tinymce-aux {
  z-index: 1600 !important;
}
.tox-tinymce {
  border: 1px solid #dbdbdb !important;
  border-radius: 10px !important;
}
.tox-tbtn__select-label {
  color: #014b7e !important;
}
.tox-collection__item-label {
  color: #014b7e !important;
}
.tox-statusbar__branding,
.tox-statusbar__path-item {
  display: none !important;
}
.tox-editor-header {
  background: white !important;
}
.tox .tox-dialog-wrap {
  z-index: 1600 !important;
}
.tox-statusbar__wordcount {
  color: #014b7e !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin: 10px !important;
  font-weight: 600 !important ;
}
.tox-statusbar {
  padding: 10px !important;
}
.tox-tbtn__select-chevron svg {
  fill: #014b7e !important;
}
.tox-tbtn__icon-wrap svg {
  fill: #014b73 !important;
}
.tox-toolbar__primary {
  background: none !important;
  margin: 1% 0 -2% 37% !important;
  margin-bottom: 1px !important;
}
.descriptiveAnswerEditor .tox-toolbar__primary {
  background: none !important;
  margin: 1% 0 -2% 48% !important;
  margin-bottom: 1px !important;
}
.tox-tbtn--bespoke {
  border: 1px solid #014b7e !important;
  border-radius: 5px !important;
  width: 15% !important;
  margin-right: 10px !important ;
  padding-right: 10px !important;
}
.tox-tbtn--bespoke:nth-child(2) {
  width: 10% !important;
  font-weight: 600 !important;
}
.tox-tbtn {
  border: 1px solid #014b7e !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}
.tox-tbtn--enabled {
  background: #014b7e !important;
}
.tox-tbtn--enabled svg {
  fill: white !important;
}
.tox-tbtn--select {
  border-radius: 10px;
}
.tox-tbtn:nth-last-child(1) {
  background: transparent !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}
.descriptiveAnswerEditor .tox-tbtn:nth-last-child(1) {
  background: white !important;
  padding: 0px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}
.tox-tbtn__select-label:nth-last-child(1) {
  color: #014b73 !important;
  cursor: pointer !important;
}
.tox-statusbar__path-divider {
  display: none !important;
}

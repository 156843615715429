.options-card{
    border: 1px solid #a1a2a5;
    margin-top: 10px;
}

.add-another{
    background-color: #ECEFF3;
    color: #7F8083;
    font-size: 14px;
    font-weight: 500;
}
.profile_delete_change_password_wrapper {
	position: fixed;
	left: 50%;
	top: 55%;
	width: 50%;
	transform: translate(-50%, -50%);
	background: #fdfdfde0;
	border: 12px solid #d2cdcd;
	border-radius: 5px;
	z-index: 10;
	padding: 4%;
  }
  .profile_delete_change_password_tag {
	color: #014b7e !important;
	font-size: 1rem !important;
	font-weight: 600 !important;
  }
  .profile_delete_change_password_tag {
	text-align: center;
	margin: 2% auto;
	display: block;
  }
  .profile_delete_change_password_button_wrapper {
	text-align: center;
	display: flex;
	justify-content: space-between;
	margin: auto;
	width: 80%;
  }
  .profile_delete_change_password_button {
	font-size: 0.9rem;
	padding: 3% 1%;
	width: 40%;
	border: none;
	border-radius: 5px;
	text-transform: uppercase;
	cursor: pointer;
	outline: none !important;
	color: white;
	background-color: #ff6b6b;
  }
  .password_wrapper {
	text-align: center;
	margin: 4% 0%;
  }
  .profile_password_wrapper {
	display: flex;
	flex-direction: column;
	color: #014b7e;
	text-align: left;
  }
  .password_label {
	margin: 2% 0%;
  }
  .profile_password_error {
	color: red;
	text-align: center;
	display: block;
	margin-bottom: 2%;
  }
  @media screen and (max-width: 700px) {
	.profile_delete_change_password_wrapper {
	  left: 50%;
	  top: 50%;
	  width: 90%;
	  transform: translate(-50%, -50%);
	  background: white;
	}
  }
  
@import url('https://fonts.googleapis.com/css2?family=Andika+New+Basic&display=swap');
body {
  font-family: 'Andika New Basic', sans-serif;
}
.createButton button {
  padding: 4.8px;
  /* font-family: 'Andika New Basic, sans-serif'; */
}

.cardsContainer {
  display: flex;
  font-family: 'Andika New Basic, sans-serif';
}

.MuiSvgIcon-root .dropdownIcon {
  color: #ff6b6b;
}
.dropdownIcon {
  font-family: 'Andika New Basic', sans-serif;
}
.MuiIconButton-root.moreHorizIcon {
  padding: 0px;
  color: #ff6b6b;
  font-family: 'Andika New Basic, sans-serif';
}

.periodDataUnavailable {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
}
.hideShowFilterText {
  color: #014b7e;
  font-size: 16px;
  margin-right: 10px;
  font-weight: 600;
  align-self: center;
}
.period_card_menu {
  cursor: pointer;
  position: relative;
}
.period_card_menu .tooltiptext {
  visibility: hidden;
  width: 150px;
  height: auto;
  /* display: flex; */
  flex-direction: column;
  background-color: #f9f9f9;
  color: #ff6b6b;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #ff6b6b;
  padding: 10px 10px;
  position: absolute;
  z-index: 1;
  top: 135%;
  left: 10%;
  margin-left: -90px;
  opacity: 0;
  transition: opacity 0.3s;
}

.period_card_menu .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 20px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #ff6b6b transparent;
}

.period_card_menu:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}

.tooltiptext {
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
}

.tooltipContainer {
  display: flex;
  flex-direction: column;
}

.paginateData {
  width: 95%;
  display: flex;
  justify-content: center;
  padding: 10px 20px !important;
}

.paginateData .MuiTypography-colorInherit {
  font-weight: bold !important;
  color: #014b7e;
}

.paginateMobileMargin {
  margin-top: -20px !important;
}

.breadCrumbFilterRow {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  justify-content: space-between;
}

.hideShowFilterIcon.MuiIconButton-label {
  /* align-self:flex-end; */
  /* align-items: flex-end; */
  /* right: 16px; */
  display: flex;
  justify-content: space-between;
  /* margin-right: 10px; */
}

.underlineRemove {
  text-decoration: none;
}

.overviewSynopsisContainer {
  display: flex;
  padding: 10px;
}

.overviewSynopsisTag {
  font-size: 16px;
  font-weight: 600;
  color: #014b7e;
}

.overviewSynopsisIcon {
  margin-left: 4px;
}

.MuiIconButton-root.bulkDownloadIconPeriodCard {
  padding: 0;
}

.showFilters {
  display: block;
}
.hideFilters {
  display: none;
}

@media screen and (max-width: 768px) {
  .cardsContainer {
    flex-direction: column;
  }
}

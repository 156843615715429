.periodcardmenu{
	cursor: pointer;
	position: relative;
}
.periodcardmenu .tooltiptext {
	visibility: hidden;
	width: 150px;
	background-color: #F9F9F9;
	color: #1b4ccb;
	text-align: left;
    border-radius: 10px;
    border: 1px solid #1b4ccb;
	padding: 14px 10px;
	position: absolute;
	z-index: 1;
	top: 135%;
	left: 10%;
	margin-left: -90px;
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  .periodcardmenu .tooltiptext::after {
	content: "";
    position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: 20px;
	border-width: 7px;
    border-style: solid;
	border-color:  transparent transparent #1b4ccb transparent ;
  }
  
  .periodcardmenu:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  
  .tooltiptext {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      font-size: 1rem;
  }

  .tooltipContainer {
      display: flex;
      flex-direction: column;
  }
.table-borderd{
    border-bottom: 1px solid red !important;
}
.viewMore {
    color: #ff6b6b;   
}

.Blue {
    color: blue;
}

.Black {
    color: red
}
.dashboard_profile {
	margin: 4% auto 0%;
	width: 70%;
	padding: 2% 1%;
  }
  .profile_breadcrumb_wrapper {
	margin: 20px auto;
	width: 95%;
  }
  .profile_wrapper {
	display: flex;
	width: 100%;
  }
  .profile_upload_image_button {
	background-color: #ff6b6b !important;
	color: white !important;
	text-align: center;
	margin-left: 2% !important;
  }
  .profile_details_wrapper {
	width: 75%;
  }
  .profile_update_button_wrapper {
	text-align: center;
	justify-content: space-between;
	margin: auto;
	width: 30%;
  }
  .profile_update_button {
	font-size: 0.9rem;
	margin: 5%;
	height: 45px;
	width: 40%;
	border: none;
	border-radius: 5px;
	text-transform: uppercase;
	cursor: pointer;
	outline: none !important;
	color: white;
	background-color: #ff6b6b;
  }
  .cancel_button_profile {
	color: grey;
	background-color: rgba(0, 0, 0, 0.12);
  }
  .profile_image_wrapper {
	width: 25%;
	text-align: center;
  }
  .profile_change_password_button {
	float: right;
	margin-top: 3% !important;
	cursor: pointer;
	color: white !important;
	margin-right: 10%;
	background-color: #ff6b6b !important;
  }
  
  .profile_change_password_button:hover {
	opacity: 0.8;
  }
  .deshboard_profilr_username {
	font-size: 1.4rem;
	font-weight: 600;
	padding: 0% 0% 1% 5%;
  }
  .profile_description {
	padding-left: 5%;
  }
  .profile_avator {
	background: #bfd0ff;
	border-radius: 0.5rem;
	width: 125px;
	height: 125px;
	margin: 3%;
	padding: 1% 1% 0% 1%;
  }
  .avator_button {
	position: absolute;
	text-align: center;
	cursor: pointer;
	left: 15.5%;
	top: 352px;
	width: 92px;
	border-radius: 0.3rem;
	padding: 0.3%;
	font-size: 0.8rem;
	background: white;
	border: 1px solid #d2d2d2;
	box-shadow: none;
	font-weight: 500;
	color: #00346a;
	overflow: hidden;
  }
  .avator_button_max {
	height: 220px;
	-webkit-transition: 0.8s;
	-moz-transition: 0.8s;
	transition: 0.8s;
  }
  .avator_button_min {
	height: 30px;
	-webkit-transition: 0.8s;
	-moz-transition: 0.8s;
	transition: 0.8s;
  }
  .avator_icons_wrapper {
	overflow-y: auto;
	padding-top: 4%;
	text-align: center;
	/* margin: auto; */
	flex-wrap: wrap;
	height: 100%;
  }
  .profile_avator_selection {
	width: 20px;
	display: inline-block;
  }
  .profile_tags {
	color: #cfcfcf;
	height: 1vw;
  }
  .profile_bio_tag {
	color: #00346a;
	font-weight: 500;
  }
  .bio_field {
	position: relative;
	display: flex;
	flex-direction: column;
	padding: 0% 1%;
	padding-left: 0;
	width: 85%;
	outline: none;
	margin: 0% 5%;
  }
  .textFieldsContainer {
	width: 100%;
	display: inline-flex;
	position: relative;
  }
  .textFieldsContainer .MuiInput-underline:before {
	border-bottom: none;
  }
  .textFieldsContainer .MuiInput-underline:after {
	border-bottom: none;
  }
  .textFieldsContainer .MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none;
  }
  .textFields {
	display: block;
	padding: 0% 1%;
	border: none;
	border-bottom: 1px solid #014b7e;
	color: #014b7e !important;
	padding-left: 0;
	width: 85%;
	outline: none;
	color: var(--secondary-blue);
	margin: 0% 5%;
  }
  
  .profile_label_tag {
	text-transform: capitalize;
	padding-left: 5%;
	color: #014b7e;
	font-weight: 600;
  }
  .password {
	display: inline-flex;
	position: relative;
	width: 100%;
  }
  .password .MuiInput-underline:before {
	border-bottom: none;
  }
  .password .MuiInput-underline:after {
	border-bottom: none;
  }
  .password .MuiInput-underline:hover:not(.Mui-disabled):before {
	border-bottom: none;
  }
  .passwordWidth {
	display: flex;
	padding: 0%;
  }
  .MuiIconButton-edgeEnd {
	margin-right: 20px !important;
  }
  .button_wrapper {
	text-align: center;
  }
  .update_button {
	margin: 2%;
	width: 10rem;
	background-color: #65d801 !important;
	border: 1px solid #65d801 !important;
	border-radius: 0.5rem !important;
  }
  .inactive {
	background-color: #cfcfcf !important ;
	color: #6a6a6a !important ;
	border: none !important;
  }
  .cancel_button {
	margin: 2%;
	width: 10rem;
	border-radius: 0.5rem !important;
	background-color: white !important;
	color: #65d801 !important;
	border: 1px solid #65d801 !important;
  }
  .dashboard_gender_options {
	display: inline-block;
	background-color: var(--primary-blue);
	/* width: 14%; */
	color: white;
	margin: 1px 10px 0 0;
	padding: 7px;
	text-align: center;
	border-radius: 0.8rem;
	font-size: 1rem;
	cursor: pointer;
  }
  .dashboard_grade_gender {
	width: 85%;
	margin: 0% 5%;
	padding: 1%;
	border-bottom: 1px solid #cfcfcf;
  }
  .dashboard_grade_gender .MuiFormControl-root .MuiInputLabel-outlined {
	z-index: 0 !important;
  }
  .dropdownContiner
	.MuiFormControl-root
	.MuiOutlinedInput-root:hover
	.MuiOutlinedInput-notchedOutline {
	border: rgba(0, 0, 0, 0.23) !important;
	outline: none;
  }
  .dropdownContiner
	.MuiFormControl-root
	.MuiOutlinedInput-root:focus
	.MuiSelect-outlined
	.MuiOutlinedInput-notchedOutline
	.MuiOutlinedInput-root {
	border: rgba(0, 0, 0, 0.23) !important;
	outline: none;
  }
  
  .formControl {
	min-width: 120;
	width: 17rem;
	cursor: pointer;
	margin: 1.5% 5%;
	box-shadow: 0.1rem 0.1rem 0.5rem rgba(143, 140, 140, 0.7);
	text-align: center;
	margin-right: 0%;
	border-radius: 0.3rem;
	color: var(--secondary-blue);
  }
  .formControl:hover {
	outline: none;
	border: none;
  }
  .gradelebel {
	color: var(--secondary-blue);
  }
  .gradeselect {
	color: var(--secondary-blue);
  }
  .gradeselect:hover {
	outline: none;
	border: none;
  }
  .dashboard_profile_loader_wrapper {
	position: absolute;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
  }
  @media screen and (max-width: 700px) {
	.profile_wrapper {
	  flex-direction: column;
	}
	.password_label {
	  margin: 6% 0% 2%;
  }
	.MuiIconButton-edgeEnd {
	  margin-right: -5px !important;
	}
	.profile_upload_image_button {
	  width: 40%;
	  margin: 2% auto !important;
	}
	.profile_details_wrapper {
	  margin-top: -5px !important;
	  width: 100%;
	}
	.profile_image_wrapper {
	  width: 100%;
	  margin-bottom: 20px;
	  text-align: center;
	  display: flex;
	  flex-direction: column;
	  justify-content: center;
	  margin-top: -30px !important ;
	}
	.profile_update_button_wrapper {
	  width: 100%;
	  display: flex;
	  flex-direction: column;
	}
	.profile_update_button {
	  width: 92%;
	}
	.dashboard_profile {
	  width: 100%;
	  padding: 8vw 2% 1% 1%;
	  margin: 1% 1%;
	}
	.profile_avator {
	  margin: auto;
	  width: 40%;
	  height: 30vw;
	  margin-bottom: 20px;
	}
	.avator_button {
	  /* position: relative; */
	  text-align: center;
	  cursor: pointer;
	  left: 10.5%;
	  top: 0%;
	  width: 25%;
	  font-size: 1.2rem;
	}
	.avator_button_min {
	  height: 22px;
	}
	.avator_button_max {
	  height: 110px;
	}
	.textFields input {
	  font-size: 1.2rem;
	}
	.profile_avator_selection {
	  width: 24px;
	}
	.profile_change_password_button {
	  margin-left: 65px !important;
	  margin-top: 25px !important; 
	  float: left;
	}
  }
  @media only screen and (max-width: 950px) and (min-width: 700px) {
	.dashboard_profile {
	  width: 100%;
	  padding-top: 5%;
	}
	.profile_update_button_wrapper{
	  margin: 40px auto;
	  width: 70%;
	}
	.profile_update_button{
	  width: 33%;
	}
  }
  
.th-tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-top: 4px solid transparent !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0px !important;
}

.th-tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active {
  border-color: #eeeeee;
  border-style: solid;
  border-width: 1px 1px 0px 1px;
  border-top: 4px solid #1b4ccb !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  border-bottom: 0px !important;
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab + .ant-tabs-tab {
  border: 1px solid #eeeeee;
}

.th-tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border: 1px solid #eeeeee;
  padding: 10px 30px;
}

.th-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: end;
}

.th-tabs {
  margin-top: -28px;
}

.ant-tabs-tab:hover {
  color: #1b4ccb;
}

@media only screen and (max-width: 600px) {
  .th-tabs .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
    justify-content: start;
  }

  .th-tabs {
    margin-top: 15px;
  }
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1b4ccb;
  text-shadow: 0 0 0.25px currentcolor;
  font-weight: 600;
}

.th-tabs .ant-tabs-nav-wrap {
  background: #f8f8f8 !important;
}

.drag-drop {
  width: 320px;
  // padding: 10px;
  .MuiDropzoneArea-root {
    min-height: 0px !important;
    padding: 3px !important;
    border: 1px solid #ff6b6b !important;
    border-radius: 10px;
  }
  .MuiDropzoneArea-textContainer{
    margin-bottom: 27px;
  }
  .MuiDropzoneArea-text{
    font-size: 1.2rem;
    margin-top: 45px;
    margin-bottom: 10px;
  }
  .MuiDropzoneArea-icon{
    width: 30px;
    height: 30px;
    color: #014b7d;
  }
}

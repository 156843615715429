.section_image{
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
}

.section_hover_image {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100%;
    height: auto;
    display: none;
}

.section1:hover .section_hover_image1{
    display: inline !important;
}
.section2:hover .section_hover_image2{
    display: inline !important;
}
.section4:hover .section_hover_image4{
    display: inline !important;
}
.section6:hover .section_hover_image6{
    display: inline !important;
}
.section8:hover .section_hover_image8{
    display: inline !important;
}

/* .create-question > :where(.css-dev-only-do-not-override-1km3mtt).ant-tabs >.ant-tabs-nav .ant-tabs-nav-list, :where(.css-dev-only-do-not-override-1km3mtt).ant-tabs >div>.ant-tabs-nav .ant-tabs-nav-list {
    position: relative;
    display: flex;
    transition: opacity 0.3s;
    width: 100%;
}
.create-question .ant-tabs-tab {
    width: 50%;
    justify-content: center;
} */
.image {
  width: 80px;
  height: 80px;
}

.school-name {
  font-size: 20px;
  font-weight: 500;
}

.bg-custom {
  background-color: #d9d9d9;
}

.border-bottom-custom {
  border-bottom: 2px solid #000;
}

.title-cutom {
  font-size: 18px;
}

.section-mark {
  right: 0;
}

.question-custom {
  font-weight: 600;
}

.option-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px; /* Adjust margin as needed */
}

.option-image-container {
  // margin-top: 10px; /* Adjust margin as needed */

  img {
    object-fit: contain;
  }
}

.th-marks-info {
  text-align: right;
}

.generated-question-div {
  img {
    padding: 10px 0px !important;
    max-height: 350px !important;
    max-width: 350px !important;
    object-fit: contain;
  }
  p {
    // margin-right: 35px !important;
    text-align: justify;
  }
}
.check-break {
  page-break-inside: avoid;
}

.last-page {
  page-break-after: avoid;
}

.section-container {
  page-break-after: auto;
}

@page {
  margin: 10mm;
  margin-bottom: 25mm;
  margin-top: 20mm;
}

@media print {
  .check-break {
    page-break-inside: avoid;
  }
}

// .check-break {
//   @media print {
//     .force-page-break {
//       page-break-before: always;
//     }
//   }
// }

// .print-container {
//   page-break-inside: avoid;
// }

// .force-page-break {
//   page-break-before: always;
// }

// /* Additional styles to ensure better handling of print layout */
// @media print {
//   .print-container {
//     width: 100%;
//     height: auto;
//   }
// }

.global_search_userdetails_wrapper{
	position: fixed;
    left: 50%;
    top: 55%;
    transform: translate(-50%,-50%);
    width: 60%;
    height: 35vw;
    background: #fdfdfde0;
    border: 12px solid #d2cdcd;
    border-radius: 5px;
    z-index: 10;
	padding: 1% 2%;
}
.user_details_wrapper{
	width: 80%;
	height: 25vw;
    margin: auto;
	padding-top: 1%;
	overflow-y: scroll;
}
.global_search_whole_page_wrapper{
	width: 100vw;
    height: 80vw;
    z-index: 100;
    left: 0%;
    background-color: #cbdcef87;
    position: fixed;
    top: 0%;
}
.user_label_tag{
	font-weight: 600;
	text-transform: capitalize;
	padding-left: 5%;
}
.user_tags{
    color: #CFCFCF;
    height: 1vw;
}
.user_textFieldsContainer {
    width: 100%;
    display: inline-flex;
    position: relative;
    margin-top: 1%;
}
.global_userdetails_tag{
	color: #014B7E;
	font-weight: 600;
	font-size: 1.5rem;
	text-align: center;
}
.close_icon_global_user_details{
	float: right;
    margin-top: -1%;
	color: #ff6b6b;
	cursor: pointer;
}
.edit_icon_global_user_details{
cursor: pointer;
}

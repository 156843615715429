/* .MuiGrid-spacing-xs-5{
  width: calc(100% + 30px) !important;
    margin: -20px !important;
} */
.profile_upload_image_button_dash{
	color:white !important;
  text-align: center;
  width: 25% !important;
  margin-bottom: 10px !important;
  margin-top: -15px !important;
  
}
.add_custom{
	color:white !important;
  text-align: center;
  /* text-size-adjust: 10px; */
  width: 8% !important;
  height: 40px !important; 
  /* margin-bottom: 10px !important; */
  margin-top: 20px !important;
  padding: 10px !important;
  margin-right: 10px !important;
  margin-left: -15px !important;
}

.add_customAdd{
	color:white !important;
  text-align: center;
  /* text-size-adjust: 10px; */
  width: 8% !important;
  height: 40px !important; 
  /* margin-bottom: 10px !important; */
  margin-top: 20px !important;
  padding: 10px !important;
  margin-right: 10px !important;
  margin-left: -15px !important;
}

.tableDesign{
  border: 1px solid #ef676a;
  border-radius: 10px;
  padding: 20px;
  margin-left: 22px;
  height: 300px;
  overflow: auto;
}

.tableDesign::-webkit-scrollbar {
  width: 6px;
  /* margin-top: 10px; */
}

.tableDesign::-webkit-scrollbar-track {
  background: #F9F9F9; 
  border-radius: 10px;
}

.tableDesign::-webkit-scrollbar-thumb {
  background: #949090; 
  border-radius: 10px;
}

.tableDesign::-webkit-scrollbar-thumb:hover {
  background: #949090;
}


.profile_avator_dash{
  width: 16vw !important;
  height: 32vh !important;
  border-radius: 50% !important;
  border: 1px solid #e0e0e0;
}

/**/
.MuiPaginationItem-textPrimary.Mui-selected  {
  color: white !important;
}

.custom_button_master{
  width: 100% !important;
  text-transform: none;
	cursor: pointer;
  outline: none !important;
}

.paginate{
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 20px !important;
  background-color: white !important;
}

.paginate .MuiTypography-colorInherit{
  font-weight: bold !important;
  color: #014b7e;
}

.breadcrumb_wrapper {
	margin: 20px auto;
	width: 95%;
}

.paginateMobileMargin {
  margin-top: -20px !important;
}

.removePadding.MuiIconButton-root {
  padding: 0 !important;
}

.filterPadding {
  padding: 20px 10px 20px 20px !important;
}

.addButtonPadding {
  padding: 15px 10px 15px 20px !important;
}

.addEditPadding {
  padding: 5px 20px 10px 20px !important;
}

.addEditButtonsPadding {
  padding: 10px 20px 20px 20px !important;
}

.labelColor {
  color: rgb(140, 140, 140) !important;
}

.cancelButton {
  background-color: #e0e0e0 !important;
}
/**/
.view_groups_header {
  color: #ff6b6b !important;
}

.hide {
  display: none !important;
}

.show {
  display: block !important;
}

.domainName {
  margin-left: -20px;
}


.switchLabel .MuiTypography-body1 {
  font-weight: 600 !important;
  color: #014b7e;
}

@media screen and (max-width: 700px) {
  .profile_avator_dash{
      width: 64vw !important;
      height: 32vh !important;
      position: relative;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
  }

  .addButton {
    margin-left: 20px !important;
  }

  .marginContainer {
    margin-bottom: 8px !important;
    /* width: 90%; */
  }
  
  .heading {
    margin-left: -90px !important;
    margin-top: 20px !important;
  }

  .profile_upload_image_button_dash{
    width: 90% !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin-bottom: 0px !important;
    margin-top: 0px !important;
  }

  .custom_button{
    width: 90% !important;
    margin-left: 12px !important;
  }

  .domainName {
    margin-top:-10px !important;
    margin-left: 12px !important ;
  }

  .mobileFlagDisp {
    float: right;
    margin-right:10px; 
    color: #014B7E;
    display: block;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
  }

  .arrowIcon{
    color: #FF6B6B;
    font-size: 22px !important;
    vertical-align: bottom;
  }

  .switchLabel .MuiTypography-body1 {
    font-weight: 600 !important;
    font-size: 0.9rem;
  }
}
.multipleChoiceContainer {
  width: 95%;
  margin: 20px auto 0 auto;
  border: 1px solid #ff6b6b;
  border-radius: 10px;
  padding: 20px;
}

.compMultipleChoiceContainer {
  width: 95%;
  margin: 20px auto 0 auto;
  border: 1px solid #014b7e;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  /* box-shadow: 0px 0px 5px 1px #ff6b6b; */
  background-image: linear-gradient(
    -45deg,
    rgba(1, 75, 126, 0.1),
    rgba(255, 107, 107, 0.2)
  );
  /* background-image: linear-gradient(45deg, #f6d2ff, #ffd9d9); */
  /* background-image: linear-gradient(45deg,rgba(246,210,255,0.3),rgba(255, 217, 217,0.5)); */
}

.questionHeaderContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 7px;
  border-bottom: 1px solid #014b7e;
}

.questionHeaderIcon .MuiIconButton-root {
  color: #ff6b6b;
  padding: 0;
}

.questionTag {
  color: #014b7e;
  font-size: 16px;
  font-weight: 600;
}

.questionHeaderIcon {
  cursor: pointer;
  position: relative;
}

.questionHeaderIcon .tooltiptext {
  visibility: hidden;
  width: 150px;
  background-color: #f9f9f9;
  color: #014b7e;
  text-align: left;
  border-radius: 10px;
  border: 1px solid #ff6b6b;
  padding: 5px 10px;
  position: absolute;
  z-index: 2;
  margin-top: 4px;
  margin-left: -88px;
  opacity: 0;
  transition: opacity 0.3s;
  display: flex;
  flex-direction: column;
}

.questionHeaderIcon .tooltiptext > div {
  padding: 5px 10px;
}

.questionHeaderIcon .tooltiptext::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: 20px;
  border-width: 7px;
  border-style: solid;
  border-color: transparent transparent #ff6b6b transparent;
}

.questionHeaderIcon:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
  z-index: 2;
}

.answerTag,
.matchingAnswerTag {
  color: #014b7e;
  font-size: 16px;
  font-weight: 600;
  width: 100%;
  padding-bottom: 7px;
  border-bottom: 1px solid #014b7e;
  margin-bottom: 10px;
}

.questionContainer {
  margin: 20px 0px;
}

.questionContainer .MuiInputBase-input {
  color: #014b7e;
  padding: 14px;
}

.attachmentContainer {
  margin-bottom: 20px;
  display: flex;
}

.attachTag {
  color: #014b7e;
  font-size: 16px;
}

.attachmentButton.MuiButtonBase-root {
  border: 1px solid #dbdbdb;
  background: white;
  box-shadow: none;
  padding: 10px;
}

.attachmentButton .MuiButton-label {
  color: #014b7e;
}

.optionsContainer {
  display: flex;
  flex-direction: column;
}
.matchingOptionsWrapper {
  border: 1px solid #1f628f;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.matchingOptionsContainer {
  display: flex;
  flex-wrap: wrap;
}

.truefalseOptionsContainer {
  display: flex;
  margin-bottom: 20px;
}

.addAnotherButton.MuiButtonBase-root {
  border: 1px solid #dbdbdb;
  background: white;
  box-shadow: none;
  padding: 15px;
  width: 100%;
  margin-top: 10px;
}

.addAnotherMatchButton.MuiButtonBase-root {
  border: 1px solid #1f628f;
  background: #def2ff;
  box-shadow: none;
  padding: 1.75rem;
  width: 100%;
  margin-top: 10px;
}

.addAnotherMatchingButtonWrapper {
  width: 49%;
}

.addAnotherMatrixButton.MuiButtonBase-root {
  border: 1px solid #1f628f;
  background: #def2ff;
  box-shadow: none;
  padding: 0.5rem;
  width: 100%;
  margin-top: 10px;
}

.addAnotherMatrixButtonWrapper {
  width: 24%;
}

.addAnotherButton .MuiButton-label,
.addAnotherMatchButton .MuiButton-label,
.addAnotherMatrixButton .MuiButton-label {
  color: #014b7e;
}

.saveAsDraftButton.MuiButtonBase-root {
  border: 1px solid #ff6b6b;
  background: white;
}

.saveAsDraftButton .MuiButton-label {
  color: #ff6b6b;
}

.buttonsAtBottomMCQ {
  border: 1px solid #dbdbdb;
  border-radius: 10px;
  padding: 20px;
  margin-top: 20px;
}

.questionImageContainer {
  margin-left: 0.85rem;
  display: flex;
}

.questionImageAttachment {
  border-radius: 4px;
  height: 50px;
  width: 75px;
  margin-right: 12px;
  box-shadow: 0px 0px 2px 0.1px #014b7e;
}

.questionImageThumbnailContainer {
  position: relative;
  display: flex;
}

.questionImageRemoveIcon {
  position: absolute;
  top: 4%;
  left: 14%;
}

.questionImageRemoveIcon .MuiIconButton-root {
  color: #ff6b6b;
  opacity: 0.5;
}

.questionImageRemoveIcon .MuiIconButton-root:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

.tox-tinymce {
  border: 1px solid #dbdbdb !important;
  border-radius: 10px !important;
}

.tox-tbtn__select-label {
  color: #014b7e !important;
}

.tox-collection__item-label {
  color: #014b7e !important;
}

.tox-statusbar__branding,
.tox-statusbar__path-item {
  display: none !important;
}

.tox-editor-header {
  background: white !important;
}

.tox-statusbar__wordcount {
  color: #014b7e !important;
  font-size: 14px !important;
  text-transform: capitalize !important;
  margin: 10px !important;
  font-weight: 600 !important ;
}

.tox-statusbar {
  padding: 10px !important;
}

.tox-tbtn__select-chevron svg {
  fill: #014b7e !important;
}

.tox-tbtn__icon-wrap svg {
  fill: #014b73 !important;
}

.tox-toolbar__primary {
  background: none !important;
  margin: 1% 0 -2% 37% !important;
  margin-bottom: 1px !important;
}

.descriptiveAnswerEditor .tox-toolbar__primary {
  background: none !important;
  margin: 1% 0 -2% 48% !important;
  margin-bottom: 1px !important;
}

.tox-tbtn--bespoke {
  border: 1px solid #ff6b6b !important;
  border-radius: 5px !important;
  width: 15% !important;
  margin-right: 10px !important ;
  padding-right: 10px !important;
}

.tox-tbtn--bespoke:nth-child(2) {
  width: 10% !important;
  font-weight: 600 !important;
}

.tox-tbtn {
  border: 1px solid #ff6b6b !important;
  border-radius: 5px !important;
  margin-right: 10px !important;
}

.tox-tbtn--enabled {
  background: #ff6b6b !important;
}

.tox-tbtn--enabled svg {
  fill: white !important;
}

.tox-tbtn--select {
  border-radius: 10px;
}

.tox-tbtn:nth-last-child(1) {
  background: #ff6b6b !important;
  padding: 10px 20px !important;
  border-radius: 10px !important;
  cursor: pointer !important;
}

.descriptiveAnswerEditor .tox-tbtn:nth-last-child(1) {
  background: white !important;
  padding: 0px !important;
  border-radius: 5px !important;
  cursor: pointer !important;
}

.tox-tbtn__select-label:nth-last-child(1) {
  color: white !important;
  cursor: pointer !important;
}

.tox-statusbar__path-divider {
  display: none !important;
}

._2fxoL ._2wk9O-gbutton {
  display: none !important;
}

.questionDisplay input {
  cursor: initial;
}

.MuiTextField-root.questionDisplay {
  background: white;
  border-radius: 10px;
}

.questionContainer .MuiIconButton-label {
  margin-bottom: -6px;
}

.questionContainer .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: 1px solid #dbdbdb;
}

.descriptiveAnswerEditor {
  margin-top: 20px;
}

.questionCreationTime {
  display: flex;
  flex-basis: 10%;
}

.clockIconContainer {
  margin-right: 7%;
  padding-bottom: 5px;
}

.timeContainer {
  font-size: 16px;
  font-weight: 600;
  color: #014b7e;
  margin-top: 1.5%;
}

.cancelButtonLabel .MuiButton-label {
  color: rgb(140, 140, 140);
}

@media screen and (max-width: 768px) {
  .questionTag {
    width: 100%;
  }

  .tox-toolbar__primary {
    margin-left: 83% !important;
  }

  .tox-tbtn:nth-last-child(1) {
    padding: 1px 10px !important;
  }

  .addAnotherMatchingButtonWrapper,
  .addAnotherMatrixButtonWrapper {
    width: 100%;
  }
}

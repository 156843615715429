.attachment-viewer {
    color: #fff;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    text-align: center;
    border: 1px solid black;
    background-color: rgba(0,0,0,.3);

}
.attachment-viewer-header {
    /* height: 55px;
    width: 225px; */
    position: fixed;
    right: 52px;
    z-index: 40;
    background-color: #323639;
    top: 79px;
}
.attachment-viewer-header-close-icon {
    color: hsla(0,0%,100%,.6);
    padding: 9px;
    position: absolute;
    right: 0;
    top: 0;
    transition-property: transform,color;
    transition-duration: .15s;
}

.attachment-viewer-frames {
    transition: transform .15s;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    
    top: 65px;
}

.attachment-viewer-frame {
    bottom: 0;
    left: 0;
    margin: 0 auto;
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 20;
    transition: transform .15s;
}
.attachment-viewer-frame-preview-wrapper {
    bottom: 0;
    /* left: 24px; */
    left: 0;
    /* overflow-x: hidden; */
    overflow-y: auto;
    position: absolute;
    top: 0;
    /* right: 24px; */
    right: 0;
}
.attachment-viewer-frame-preview-image-wrapper {
    height: auto;
}
.attachment-viewer-frame-preview {
    box-sizing: border-box;
    padding: 48px 24px 112px;
    height: 100%;
}
.attachment-viewer-frame-preview-image {
    border-radius: 3px;
    max-width: 100%;
    /* opacity: 0; */
    position: relative;
    z-index: 10;
    transition: transform .15s,opacity .4s;
}


.attachment-viewer-overlay {
    height: 80px;
    bottom: 0;
    z-index: 30;
}
.attachment-viewer-overlay, .attachment-viewer-underlay {
    background-color: rgba(0,0,0,.7);
    position: absolute;
    right: 0;
    left: 0;
}

.attachment-viewer-frame-details {
    bottom: 0;
    box-sizing: border-box;
    color: #fff;
    height: 60px;
    left: 0;
    padding: 12px 10%;
    position: absolute;
    right: 0;
    z-index: 30;
}
.attachment-viewer-frame-details-title{   
    font-weight: 600;
    margin: 0 0 8px;
}

.attachment-viewer-frame-details a, .attachment-viewer-frame-details a:hover {
    color: #fff;
}

.attachment-viewer-next-frame-btn {
    right: 0;
    width: 10%;
}
.attachment-viewer-prev-frame-btn {
    left: 0;
    width: 10%;
}
 
/*New Css*/
.attachment-viewer-buttons {
    display: flex;
    flex-direction: row;
    justify-content:space-evenly;
    position: absolute;
    bottom: -4%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 40;
    width: 18%; 
}

.attachment-viewer-buttons .MuiIconButton-root {
    height:2.2rem;
    width: 2.2rem;
    padding: 2px;
    opacity: 0.7;
    margin-top: 6%;
}

.attachment-viewer-buttons .MuiIconButton-root svg {
    font-size:2rem;
}

.attachment-viewer-prev-frame-btn.MuiIconButton-root,
.attachment-viewer-next-frame-btn.MuiIconButton-root {
    background-color: black;
    opacity: 0.7;
}

.attachment-viewer-prev-frame-btn.MuiIconButton-root.Mui-disabled ,
.attachment-viewer-next-frame-btn.MuiIconButton-root.Mui-disabled {
    background-color: rgba(140,140,140);
}

.attachment-viewer-prev-frame-btn.MuiIconButton-root:hover ,
.attachment-viewer-next-frame-btn.MuiIconButton-root:hover {
    background-color: black;
    opacity: 1;
}

.attachment-viewer-zoom-out-btn.MuiIconButton-root:hover,
.attachment-viewer-zoom-in-btn.MuiIconButton-root:hover {
    opacity: 1;
}

.attachment-viewer-next-frame-btn, .attachment-viewer-prev-frame-btn {
    position: absolute !important;
    height: 100px;
    bottom: 4%;
    z-index: 40;
    height:40px;
    width: 40px;
}

.attachment-viewer-next-frame-btn.MuiIconButton-root,
.attachment-viewer-prev-frame-btn.MuiIconButton-root svg {
    font-size: 2rem;
}

.attachment-viewer-next-frame-btn {
    right: 4%;
}
.attachment-viewer-prev-frame-btn {
    left: 4%;
}

.attachment-viewer-frame-preview-iframe {
    background-color: #fff;
    border-radius: 3px;
    border: 0;
    box-sizing: border-box;
    height: 100%;
    /* opacity: 0; */
    padding: 12px;
    width: 100%;
}
.attachment-viewer-frame-preview-placeholder-link {
    border-radius: 3px;
    box-sizing: border-box;
    color: #fff;
    display: block;
    margin: 8px auto;
    padding: 16px 24px;
    max-width: 250px;
}

.pdf-document {
    margin: 20px auto 100px auto;
    display:flex;
    align-items: center;
    justify-content:center;
}

.attachment-viewer-center-frame {
    /* font-weight: 600; */
    color: #000;
}
.attachment-viewer-center-frame p {
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0px 0px 20px 0px gray;
    background: #fff;
}

@media screen and (max-width: 768px) { 
    .pdf-page canvas{
        width: 100% !important;
    }
}

@media print {
    .pdf-document {
        display: none;
    }
}
.canvasstyle {
    width: 100% !important;
    height: 225px !important;
}
.omselect{
    padding-top: 16px;
    margin-left: -68px;
    
}

.omselect1{
    padding-top: 16px;
    width:40vh !important;
}
.ebook__title{
  width: 30%;
  padding: 11px;
  border-radius: 5px;
  border: 1px solid rgb(211, 211, 211);
}
.toolbar{
  
    min-height: 27px !important;
}
.loader{
    border: 10px solid #500323b3 !important;
    border-radius: 50% !important;
    border-top: 10px solid #f3f3f3!important;
    width: 80px !important;
    height: 80px !important;
    -webkit-animation: spin 2s linear infinite; /* Safari */
    animation: spin 2s linear infinite;
  }
  
  /* Safari */
  @-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  } 
.js-line-range{
  display: none;
  background-color: #fff;
  padding: 5px;
}
.js-color-picker{
  cursor: copy;
  width: 30px;
}
.formControl{
  margin: 20px 0px;
  min-Width: 120;
}
.selectEbookType {
  width: 125px;
}
#latestImg{
  border: 2cm;
}
/* added css for responsive : Shivesh*/
.tools__annotate{
  display: flex;
  float: right;
  margin-top: 3%;
  list-style-type: none;
}
.zoom-icon{
  color: #ff6b6b;
    position: fixed;
    bottom: 10%;
    right: 5%;
    font-size: 3rem !important;
    background-color: rgb(255, 255, 255);
    padding: 5px;
    border-radius: 50%;
    box-shadow: 1px 1px 3px 1px;
}
.next-prev{
  height: 35px;
    width: 35px;
    border: 2px solid;
    border-radius: 50%;
}
.pager-Pagination {
  list-style-type: none;
  position: absolute;
  width: 100%;
  bottom: 0;
  background-color: red;
}
.pager-coustom {
  display: flex;
  margin: 0px;
  padding: 10px;
  background-color:#ff6b6b;
  color: #fff;
  position: fixed;
  width: 100%;
  bottom: 0;
}
/* letest changes */
/* .pager-coustom{
  display: flex;
    margin-top: 10px;
    padding: 10px;
    background-color: #5d2449;
    color: #fff;
    width: 100%;
    bottom: 0;
} */
#background__pdf {
  margin-top: 5%;
  margin-bottom: 5%;
}
/* letest changes */
.right-icon {
  text-align: left;
}
.left-icon {
  float: right;
}
.subject-name h2{
  text-align: center;
    font-size: 14px;
    padding: 0;
    margin-top: 15px;
    color:white
}
#dpage{
  border: none;
  width: 20px;
  padding: 0;
  text-align: center; 
}
/* #background__pdf {
  display: block;
  margin: 0 auto;
  align-items: center;
  width: fit-content;
} */
/* #background__pdf {
  margin-top: 5% !important;
}
#child__canvas {
  margin-top: 5% !important;
} */

.drwaing-resposive {
  margin-top: 10% !important;
  height: 100vh !important;
  width: fit-content !important;
  cursor: url('https://img.icons8.com/emoji/20/000000/fountain-pen-emoji.png')1 30, crosshair !important;
}
.drwaing-resposive1 {
  margin-top: 10% !important;
  height: 100vh !important;
  width: fit-content !important;
  cursor: url('https://img.icons8.com/color/30/000000/eraser.png')1 30, pointer !important;
} 
@media screen and (max-width: 768px) {
  .ebook-pagination {
    background: #fff;
    box-shadow: 0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12);
    position: fixed;
    bottom: 0;
    left: 30%;
    margin: 1%;
    z-index: 1;
  }
  .drwaing-resposive {
    margin-top: 10% !important;
    height: 100vh !important;
    width: fit-content !important;
    cursor: url('https://img.icons8.com/emoji/20/000000/fountain-pen-emoji.png')1 30, crosshair !important;
  }
  .drwaing-resposive1 {
    margin-top: 10% !important;
    height: 100vh !important;
    width: fit-content !important;
    cursor: url('https://img.icons8.com/color/30/000000/eraser.png')1 30, pointer !important;
  } 
  #canvastyleview{
    margin-top: 10% !important;
    height: 100vh !important;
  }
  /* #background__pdf {
    margin-top: -3% !important;
  } */
}
@media screen and (max-width: 480px) {
  .drwaing-resposive {
    margin-top: 15%!important;
    margin-bottom: 0% !important;
    cursor: url('https://img.icons8.com/emoji/20/000000/fountain-pen-emoji.png')1 30, crosshair !important;
  }
  .drwaing-resposive1 {
    margin-top: 15%!important;
    margin-bottom: 0% !important;
    cursor: url('https://img.icons8.com/color/30/000000/eraser.png')1 30, pointer !important;
  }
  #canvastyleview{
    margin-top: 10%!important;
    margin-bottom: 0% !important;
  }
  #background__pdf {
    margin: 0 auto;
    width: fit-content;
  }
  .ebook-pagination {
    left:19%;
  } 
  .ebook-filter_grid {
    display: block !important;
  }
  /* .canvasstyle {
    height: 30vh !important;
} */
.marker_undo {
  margin-top: 10%;
}
#background__pdf {
  margin-top: 5%;
  margin-bottom: 10%;
  padding: 5%;
}
.tools__annotate {
  margin-top: 10%;
}
}

.erp_loader_wrapper {
    width: 100vw;
    height: 80vw;
    z-index: 100;
    background-color: #cbdcef87;
    position: fixed;
}
.erp_loader-container{
	left: 50%;
	top: 50%;
	transform: translate(-50%,-50%);
	position: fixed;
}
.erp_loader--title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1.5rem;
  position: relative;
}

.erp_loader--title:after {
  position: absolute;
  content: '';
  -webkit-animation: Dots 2s cubic-bezier(0, 0.39, 1, 0.68) infinite;
  animation: Dots 2s cubic-bezier(0, 0.39, 1, 0.68) infinite;
}

.erp_loader {
  width: 40px;
  height: 40px;
  margin: auto;
  position: relative;
  animation: erp_loader 2.5s infinite linear both;
}

.erp_loader-dot {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  animation: erp_loader-dot 2s infinite ease-in-out both;
}

.erp_loader-dot:before {
  content: '';
  display: block;
  width: 25%;
  height: 25%;
  background-color: rgb(245, 58, 58);
  border-radius: 100%;
  animation: erp_loader-dot-before 2s infinite ease-in-out both;
}

.erp_loader-dot:nth-child(1) {
  animation-delay: -1.1s;
}
.erp_loader-dot:nth-child(2) {
  animation-delay: -1s;
}
.erp_loader-dot:nth-child(3) {
  animation-delay: -0.9s;
}
.erp_loader-dot:nth-child(4) {
  animation-delay: -0.8s;
}
.erp_loader-dot:nth-child(5) {
  animation-delay: -0.7s;
}
.erp_loader-dot:nth-child(6) {
  animation-delay: -0.6s;
}
.erp_loader-dot:nth-child(1):before {
  animation-delay: -1.1s;
}
.erp_loader-dot:nth-child(2):before {
  animation-delay: -1s;
}
.erp_loader-dot:nth-child(3):before {
  animation-delay: -0.9s;
}
.erp_loader-dot:nth-child(4):before {
  animation-delay: -0.8s;
}
.erp_loader-dot:nth-child(5):before {
  animation-delay: -0.7s;
}
.erp_loader-dot:nth-child(6):before {
  animation-delay: -0.6s;
}

@keyframes erp_loader {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes erp_loader-dot {
  80%,
  100% {
    transform: rotate(360deg);
  }
}

@keyframes erp_loader-dot-before {
  50% {
    transform: scale(0.4);
  }
  100%,
  0% {
    transform: scale(1);
  }
}

.view_groups_header {
  color: #ff6b6b !important;
}
.view_group_delete_modal {
  border: 12px solid #d2cdcd;
  padding: 4%;
}
.view_group_delete_modal_title {
  color: #014b7e;
}
.view_group_delete_alert_tag {
  color: #014b7e !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.view_group_delete_alert_button_wrapper {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.view_group_delete_alert_button {
  font-size: 0.9rem;
  padding: 3% 10%;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none !important;
  color: white;
  background-color: #ff6b6b;
}
.view_group_table_sections {
  width: 20%;
}
.view_group_delete_alert_button_cancel {
  color: rgb(140, 140, 140) !important;
  background-color: #e0e0e0 !important;
}

.view_group_delete_alert_button {
  background-color: #ff6b6b !important;
}
.table-pagination-view-group {
  text-align: center;
  display: flex;
  justify-content: center;
  color: #014b7e !important;
}
.table-pagination-view-group .MuiTablePagination-caption {
  font-weight: 600;
}
.MuiTableCell-head {
  color: #014b7e !important;
  font-weight: 600 !important;
  text-align: center !important;
}
.MuiTableCell-body {
  color: #014b7e !important;
  font-weight: 500 !important;
  text-align: center !important;
}
.MuiTableCell-stickyHeader {
  background-color: white !important;
}
.group_view_activate_button {
  background-color: green;
  color: white;
}
.group_view_deactivate_button {
  background-color: red;
  color: white;
}
.group_view_button {
  margin-left: 1%;
  margin-right: 1%;
  outline: none;
  border: none;
  width: 30%;
  height: 30px;
  vertical-align: bottom;
}
.group_view_button:hover {
  transform: scale(1.1);
  box-shadow: 1px 3px 5px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}

.group_view_delete_button:hover {
  transform: none;
  box-shadow: none;
}
.books__pagination {
  width: 100%;
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  background-color: white;
  box-shadow: none;
}

.books__pagination * {
  color: #013e56 !important;
  fill: #013e56 !important;
}

.books__pagination .MuiPaginationItem-page.Mui-selected {
  background-color: #013e56;
  color: white !important;
  box-shadow: 0.5px 2px 3px 2px rgba(0, 0, 0, 0.295);
}
.paginate {
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 50px;
  padding: 15px;
  background-color: #f3e8e8;
}
.view_group_delete_alert_wrapper {
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: #fdfdfde0;
  border: 12px solid #d2cdcd;
  border-radius: 5px;
  z-index: 10;
  padding: 4%;
}
.view_group_delete_alert_tag {
  color: #014b7e !important;
  font-size: 1rem !important;
  font-weight: 600 !important;
}
.view_group_delete_alert_button_wrapper {
  text-align: center;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
}
.view_group_delete_alert_button {
  font-size: 0.9rem;
  padding: 3% 10%;
  border: none;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  outline: none !important;
  color: white;
  background-color: #ff6b6b;
}
.add_edit_user_role {
  width: 80%;
}
.view_group_white_space_wrapper {
  width: 100%;
  margin-top: 20px;
  padding-top: 1px;
  background-color: white;
}
.message_log_expand_manage {
  display: none;
}
.hide {
  display: none !important;
}
.show {
  display: table-cell !important;
}
.view_users_breadcrumb_wrapper {
	margin: 20px auto;
	width: 95%;
  }
.view_user_table_wrapper .view_user_table tr th:not(:last-child):after {
	content: '';
	height: 50%;
	width: 2px;
	position: absolute;
	right: 0;
	top: 50%;
	transform: translateY(-50%);
	background-color: #ff6b6b;
  }

@media screen and (max-width: 700px) {
  .message_log_expand_manage {
    float: right;
    margin-right: 30px;
    color: #014b7e;
	display: block;
	font-weight: 600;
  }
}

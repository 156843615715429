.optionBox {
  display: flex;
  flex-wrap: wrap;
  padding: 15px;
  background: #f3f3f3;
  border-radius: 8px;
  margin: 10px 0;
}

.matchingOptionBox {
  display: flex;
  padding: 15px 0 15px 15px;
  background: #def2ff;
  border-radius: 8px;
  margin: 10px 0;
  width: 49%;
}

.matchingOptionBox:nth-child(odd) {
  margin-right: 2%;
}

.matrixOptionBox {
  display: flex;
  padding: 15px 0 0 15px;
  background: #def2ff;
  border-radius: 8px;
  margin: 10px 0;
  width: 24%;
}

.matrixOptionBox:not(:nth-child(4n + 4)) {
  margin-right: 1.3%;
}

.trueFalseOptionBox {
  display: flex;
  padding: 15px;
  background: #f3f3f3;
  border-radius: 8px;
  width: 100%;
  margin-top: 10px;
}

.trueFalseOptionBox:first-child {
  margin-right: 25px;
}

.optionTag {
  color: #014b7e;
  font-size: 16px;
  align-self: center;
  flex-basis: 12%;
  margin: 0 1% 0 0.5%;
  display: flex;
  flex-direction: column;
}

.trueFalseOptionTag {
  color: #014b7e;
  font-size: 16px;
  font-weight: 600;
  align-self: center;
  margin: 0 1% 0 2%;
  display: flex;
  flex-direction: column;
}

.optionInput {
  flex-basis: 40%;
}

.optionInput .MuiInputBase-root {
  background: white;
}

.addImageButtonContainer {
  align-self: center;
}

.matchingOptionInput {
  flex-basis: 50%;
}

.matchingOptionInput .MuiOutlinedInput-notchedOutline {
  border: none;
}

.matrixOptionInput .MuiOutlinedInput-notchedOutline {
  border: none;
}

.attachmentIconContainer {
  position: relative;
  margin-left: 3%;
}

.attachmentIconMatching.MuiButtonBase-root {
  bottom: 20%;
  right: 25%;
  padding: 0;
}

.deleteMatchingIconContainer.MuiButtonBase-root {
  bottom: 20%;
  right: 20%;
  padding: 0;
}

.deleteMatrixIconContainer.MuiButtonBase-root {
  bottom: 60%;
  right: 35%;
  padding: 0;
}

.matchTheFollowingIndex {
  color: #014b7e;
  font-size: 16px;
  border-right: 1px solid #014b7e;
  height: 20px;
  padding-right: 2%;
  margin-right: 5%;
}

.correctOptionTag {
  color: #006927;
  font-size: 16px;
  font-weight: 600;
}

.answerBox .MuiInputBase-input {
  color: #014b7e;
  padding: 14px;
}

.matchingAnswerBox .MuiInputBase-input {
  color: #014b7e;
  padding: 0 0 40px 0;
  font-size: 16px;
}

.matrixAnswerBox .MuiInputBase-input {
  color: #014b7e;
  padding: 0;
  font-size: 16px;
}

.checkboxContainer {
  align-self: center;
}

.optionImageContainer {
  margin-left: auto;
  margin-right: 5px;
  align-self: center;
  display: flex;
}

.optionImageAttachment {
  border-radius: 4px;
  height: 50px;
  width: 75px;
  margin-right: 12px;
  box-shadow: 0px 0px 2px 0.1px #014b7e;
}

.optionImageThumbnailContainer {
  position: relative;
}

.optionImageRemoveIcon {
  position: absolute;
  top: 1%;
  left: 15%;
}

.optionImageRemoveIcon .MuiIconButton-root {
  color: #ff6b6b;
  opacity: 0.5;
  font-size: 1px;
}

.optionImageRemoveIcon .MuiIconButton-root:hover {
  opacity: 0.9;
  transform: scale(1.1);
}

@media all and (max-width: 786px) {
  .optionTag {
    flex-basis: auto;
    margin: 0 1% 2% 1%;
  }

  .optionInput {
    flex-basis: auto;
  }

  .matchingOptionBox {
    display: flex;
    margin: 10px 0;
    width: auto;
  }

  .matrixOptionBox {
    display: flex;
    margin: 10px 0;
    width: auto;
  }

  .optionImageContainer {
    margin-left: 1%;
    margin-top: 5%;
  }
  
  .optionImageAttachment {
    margin-right: 0;
  }
  
  .attachmentIconContainer {
    position: relative;
    margin-left: auto;
    margin-right: -10%;
  }

}

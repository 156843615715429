.th-clicker-checkbox {
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
  }
}
.options-container {
  .image-container {
    position: relative;
    .image-delete-icon {
      position: absolute;
      top: -10%;
      right: -10%;
    }
  }
}

.th-tabs .ant-tabs-nav-wrap {
  background: white !important;
}
.ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  justify-content: end;
}

.th-tabs .ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom: 0px !important;
}
.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  font-weight: 400 !important;
}

.th-clicker-correct-answer {
  outline: 2px solid #4ade80;
  border-radius: 4px;
}
.ant-image-preview-wrap,
.ant-image-preview-operations-wrapper {
  z-index: 3000 !important;
}

.dropdownIcon .MuiInputBase-root {
    border-radius: 10px;
}
.dropdownIcon  .MuiFormLabel-root {
    color: #014b7e;
} 
.dropdownIcon .MuiFormLabel-root.Mui-focused {
    color: #014b7e;
    font-weight: 600;
}
.dropdownIcon .MuiButtonBase-root {
    color: #ff6b6b;
}
.dropdownIcon .MuiAutocomplete-input {
    color: #014b7e;
}
.dropdownIcon .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-weight: 600;
}
.modifyDesign.MuiButton-root {
    border-radius: 10px;
}

.outlined-btn {
  background-color: #ffffff !important;
  border: 1px solid $primary-color !important;
  .MuiButton-label {
    color: $primary-color;
  }
  &:hover {
    background-color: #fafafa !important;
  }
}

.createButton button{
    padding: 4.8px;
}

.cardsContainer {
    display: flex;
}

.MuiSvgIcon-root .dropdownIcon {
  color: #ff6b6b;
}

.MuiIconButton-root.moreHorizIcon {
  padding: 0px;
  /* color: #ff6b6b; */
}

.periodDataUnavailable {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 5%;
}

.period_card_menu{
	cursor: pointer;
	position: relative;
}
.period_card_menu .tooltiptext {
	visibility: hidden;
	width: 150px;
	background-color: #F9F9F9;
	color: #ff6b6b;
	text-align: left;
    border-radius: 10px;
    border: 1px solid #ff6b6b;
	padding: 14px 10px;
	position: absolute;
	z-index: 1;
	top: 135%;
	left: 10%;
	margin-left: -90px;
	opacity: 0;
	transition: opacity 0.3s;
  }
  
  .period_card_menu .tooltiptext::after {
	content: "";
    position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: 20px;
	border-width: 7px;
    border-style: solid;
	border-color:  transparent transparent #ff6b6b transparent ;
  }
  
  .period_card_menu:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
  }
  
  .tooltiptext {
      display: flex;
      justify-content: space-between;
      font-size: 1rem;
  }

  .tooltipContainer {
      display: flex;
      flex-direction: column;
  }

  .paginateData{
    width: 95%;
    display: flex;
    justify-content: center;
    padding: 10px 20px !important;
  }
  
  .paginateData .MuiTypography-colorInherit{
    font-weight: bold !important;
    color: #014b7e;
  }
  
  .paginateMobileMargin {
    margin-top: -20px !important;
  }

  .breadCrumbFilterRow {
    display: flex;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: space-between;
  }

  .hideShowFilterIcon {
    align-self: center;
  }

  .underlineRemove {
    text-decoration: none;
  }

  .overviewSynopsisContainer {
    display: flex;
    padding: 10px;
  }

  .overviewSynopsisTag {
    font-size: 16px;
    font-weight: 600;
    color: #014b7e;
  }
  
  .overviewSynopsisIcon {
    margin-left:4px;
  }

  .MuiIconButton-root.bulkDownloadIconPeriodCard {
    padding: 0;
  }

  .showFilters {
    display: block;
  }
  .hideFilters {
    display: none;
  }

  .dropdownIcon .MuiInputBase-root {
    border-radius: 10px;
  }

  .dropdownIcon  .MuiFormLabel-root {
      color: #014b7e;
  } 

  .dropdownIcon .MuiFormLabel-root.Mui-focused {
      color: #014b7e;
      font-weight: 600;
  }

  .dropdownIcon .MuiButtonBase-root {
      color: #ff6b6b;
  }

  .dropdownIcon .MuiAutocomplete-input {
    color: #014b7e;
  }

  .dropdownIcon .MuiInputLabel-outlined.MuiInputLabel-shrink {
    font-weight: 600;
  }

  .modifyDesign.MuiButton-root {
    border-radius: 10px; 
  }

  @media screen and (max-width:768px) {
    .cardsContainer {
        flex-direction: column;
    }
}
.th-drawer .ant-drawer-body {
  padding: 12px !important;
}

.th-drawer .ant-collapse-header-text {
  width: 100% !important;
}
.th-drawer .ant-drawer-header {
  background-color: #1b4ccb !important;
  color: #fff !important;
  border-bottom: 2px solid #d9d9d9 !important;
}
.th-drawer .ant-drawer-title {
  color: #fff !important;
  border-top-left-radius: 8px !important;
  font-weight: 500;
  font-size: 18px;
}
.th-drawer .ant-drawer-content {
  border-top-left-radius: 8px !important;
}
